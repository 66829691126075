<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe>
            <template slot-scope="{ row }" slot="monthly_fee">
                <span class="tw-font-bold text-blue">
                    {{ formatCurrencyWithCode(row.monthly_fee, row.currency) }}
                </span>
            </template>
            <template slot-scope="{ row }" slot="deposit_amount">
                <span class="tw-font-bold text-blue">
                    {{
                        formatCurrencyWithCode(row.deposit_amount, row.currency)
                    }}
                </span>
            </template>
            <template slot-scope="{ row }" slot="province_code">
                {{ row.province ? row.province.name_en : '' }}
            </template>
            <template slot-scope="{ row }" slot="district_code">
                {{ row.district ? row.district.name_en : '' }}
            </template>
            <template slot-scope="{ row }" slot="commune_code">
                {{ row.commune ? row.commune.name_en : '' }}
            </template>
            <template slot-scope="{ row }" slot="village_code">
                {{ row.village ? row.village.name_en : '' }}
            </template>
            <template slot-scope="{ row }" slot="action">
                <a
                    href="#"
                    class="text-primary ml-2"
                    @click.prevent="onEdit(row)"
                    v-tooltip="$t('edit')"
                >
                    <Icon type="ios-create" size="20" />
                </a>
                <a
                    class="text-danger ml-2"
                    @click.prevent="onDelete(row)"
                    v-tooltip="$t('delete')"
                >
                    <Icon type="ios-trash" size="20" />
                </a>
            </template>
        </Table>
        <Modal
            v-model="showContract"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            width="1000"
            :title="$t('branch.officeRental')"
        >
            <RentalContract
                ref="contract_action"
                @cancel="clearEdit"
                @fetchData="fetchData"
            />
            <Spin size="large" fix v-if="loading"> </Spin>
        </Modal>
    </div>
</template>
<script>
import RentalContract from './rental-contract'
import { orderBy } from 'lodash'
import { mapGetters } from 'vuex'
export default {
    components: {
        RentalContract
    },
    props: {
        row: Object
    },
    data () {
        return {
            loading: false,
            showContract: false,
            model: {
                office_rentals: [
                    {
                        contract_id: 0,
                        contract_period: null,
                        monthly_fee: null,
                        deposit_amount: null,
                        currency_id: 1,
                        owner_phone_number: null,
                        remarks: null,
                        contract_start_date: null,
                        contract_expiry_date: null,
                        attached_file: null,
                        village_code: null,
                        commune_code: null,
                        district_code: null,
                        province_code: null,
                        mime_type: null,
                        attached_file_name: null,
                        notification_receiver: []
                    }
                ]
            },
            currencies: []
        }
    },
    computed: {
        ...mapGetters(['formatCurrencyWithCode']),
        resources () {
            return orderBy(this.row.rental_contracts)
        },
        columns () {
            return [
                {
                    title: this.$t('branch.contractStartDate'),
                    key: 'contract_start_date',
                    sortable: true
                },
                {
                    title: this.$t('branch.contractExpiryDate'),
                    key: 'contract_expiry_date',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('branch.monthlyFee'),
                    slot: 'monthly_fee',
                    key: 'monthly_fee',
                    sortable: true
                },
                {
                    title: this.$t('branch.depositAmount'),
                    slot: 'deposit_amount',
                    key: 'deposit_amount',
                    sortable: true
                },
                {
                    title: this.$t('branch.ownerPhoneNumber'),
                    key: 'owner_phone_number',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('branch.provinceCode'),
                    slot: 'province_code',
                    width: 200,
                    sortable: true
                },
                {
                    title: this.$t('branch.districtCode'),
                    slot: 'district_code',
                    sortable: true
                },
                {
                    title: this.$t('branch.communeCode'),
                    slot: 'commune_code',
                    sortable: true
                },
                {
                    title: this.$t('branch.villageCode'),
                    slot: 'village_code',
                    sortable: true
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    align: 'center',
                    width: 150
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('corporate/branch/fetch', attributes)
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit (record) {
            this.showContract = true
            this.$store.commit('corporate/branch/SET_EDIT_DATA', record)
            this.$refs.contract_action.fetchResource()
            this.$refs.contract_action.setEditData()
        },
        clearEdit () {
            this.showContract = false
            this.$store.commit('corporate/branch/SET_EDIT_DATA', {})
        },
        async onDelete (record) {
            record._deleting = true
            this.$store
                .dispatch('corporate/branch/removeContract', record.contract_id)
                .then(response => {
                    this.fetchData()
                    record._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    record._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BRANCH',
                desc: not.text
            })
        }
    }
}
</script>
